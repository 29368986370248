import React from 'react';

import { parse, stringify } from '../../../util/urlHelpers';
import { DatePicker, Select } from '../../../components';

import css from './DateSelect.css';

const date = new Date();
const currentYear = date.getFullYear();
const currentMonth = date.getMonth();
const firstDay = new Date(currentYear, currentMonth, 2).toISOString().split('T')[0];
const lastDay = new Date().toISOString().split('T')[0];

const timePeriodText = [
  { text: 'Console.allTime', value: false },
  { text: 'Console.partTime', value: true },
];

const DateSelect = ({ history, pathname, search, intl }) => {
  const { start = firstDay, end = lastDay, periodTime = false } = parse(search);

  const handleDateRangeSubmit = ({ start, end }) => {
    history.push({ pathname, search: `${stringify({ ...parse(search), page: 1, start, end })}` });
  };

  const handleTimePeriodChange = event => {
    const startDay = event.target.value === 'true' ? firstDay : undefined;
    const endDay = event.target.value === 'true' ? lastDay : undefined;

    history.push({
      pathname,
      search: `${stringify({
        ...parse(search),
        page: 1,
        start: startDay,
        end: endDay,
        periodTime: event.target.value,
      })}`,
    });
  };

  return (
    <div className={css.dateContainer}>
      <div className={css.selectWrapper}>
        <Select
          value={periodTime || false}
          onChange={handleTimePeriodChange}
          label={intl.formatMessage({ id: "Console.time" })}
          isValidation={false}
        >
          {timePeriodText.map(({ text, value }) => (
            <option value={value}>{intl.formatMessage({ id: text })}</option>
          ))}
        </Select>
      </div>
      {periodTime && (
        <DatePicker
          onSubmit={handleDateRangeSubmit}
          value={{ startDate: start, endDate: end }}
          disabled={true}
        />
      )}
    </div>
  );
};

export default DateSelect;
