import pick from 'lodash/pick';
import config from '../../config';
import { denormalisedResponseEntities } from '../../util/data';
import { initiatePrivileged, transitionPrivileged, validatePromoCode, utilizePromoCode } from '../../util/api';
import { storableError } from '../../util/errors';
import {
  TRANSITION_REQUEST_PAYMENT,
  TRANSITION_REQUEST_PAYMENT_AFTER_ENQUIRY,
  TRANSITION_REQUEST_PUSH_PAYMENT,
  TRANSITION_REQUEST_PUSH_PAYMENT_AFTER_ENQUIRY,
  TRANSITION_CONFIRM_PAYMENT,
  TRANSITION_CONFIRM_PAYMENT_INSTANT_BOOKING,
  isPrivileged
} from '../../util/transaction';
import * as log from '../../util/log';
import { fetchCurrentUserHasOrdersSuccess, fetchCurrentUser } from '../../ducks/user.duck';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { daysBetween } from '../../util/dates';

import purchaseEvent from './purchaseEvent';

// ================ Action types ================ //

export const SET_INITAL_VALUES = 'app/CheckoutPage/SET_INITIAL_VALUES';

export const INITIATE_ORDER_REQUEST = 'app/CheckoutPage/INITIATE_ORDER_REQUEST';
export const INITIATE_ORDER_SUCCESS = 'app/CheckoutPage/INITIATE_ORDER_SUCCESS';
export const INITIATE_ORDER_ERROR = 'app/CheckoutPage/INITIATE_ORDER_ERROR';

export const CONFIRM_PAYMENT_REQUEST = 'app/CheckoutPage/CONFIRM_PAYMENT_REQUEST';
export const CONFIRM_PAYMENT_SUCCESS = 'app/CheckoutPage/CONFIRM_PAYMENT_SUCCESS';
export const CONFIRM_PAYMENT_ERROR = 'app/CheckoutPage/CONFIRM_PAYMENT_ERROR';

export const SPECULATE_TRANSACTION_REQUEST = 'app/ListingPage/SPECULATE_TRANSACTION_REQUEST';
export const SPECULATE_TRANSACTION_SUCCESS = 'app/ListingPage/SPECULATE_TRANSACTION_SUCCESS';
export const SPECULATE_TRANSACTION_ERROR = 'app/ListingPage/SPECULATE_TRANSACTION_ERROR';

export const VALIDATE_PROMO_REQUEST = 'app/CheckoutPage/VALIDATE_PROMO_REQUEST';
export const VALIDATE_PROMO_SUCCESS = 'app/CheckoutPage/VALIDATE_PROMO_SUCCESS';
export const VALIDATE_PROMO_ERROR = 'app/CheckoutPage/VALIDATE_PROMO_ERROR';

export const STRIPE_CUSTOMER_REQUEST = 'app/CheckoutPage/STRIPE_CUSTOMER_REQUEST';
export const STRIPE_CUSTOMER_SUCCESS = 'app/CheckoutPage/STRIPE_CUSTOMER_SUCCESS';
export const STRIPE_CUSTOMER_ERROR = 'app/CheckoutPage/STRIPE_CUSTOMER_ERROR';

export const FETCH_TRANSACTION_REQUEST = 'app/ListingPage/FETCH_TRANSACTION_REQUEST';
export const FETCH_TRANSACTION_SUCCESS = 'app/ListingPage/FETCH_TRANSACTION_SUCCESS';
export const FETCH_TRANSACTION_ERROR = 'app/ListingPage/FETCH_TRANSACTION_ERROR';

const EMULATED_TRANSACTION = {
  firstName: 'emulated',
  lastName: 'emulated',
  receiverName: 'emulated',
  phone: 'emulated',
  emulate: 'with-warnings',
};

// ================ Reducer ================ //

const initialState = {
  listing: null,
  listings: [],
  provider: null,
  bookingData: null,
  bookingDates: null,
  totalNumberOfOrders: null,
  speculateTransactionInProgress: false,
  speculateTransactionError: null,
  speculatedTransaction: null,
  transaction: null,
  initiateOrderError: null,
  confirmPaymentError: null,
  stripeCustomerFetched: false,
  validatePromoInProgress: false,
  validatePromoError: null,
  validatePromoInfo: null,
  currentTransaction: null
};

export default function checkoutPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SET_INITAL_VALUES:
      return { ...initialState, ...payload };

    case SPECULATE_TRANSACTION_REQUEST:
      return {
        ...state,
        speculateTransactionInProgress: true,
        speculateTransactionError: null,
        speculatedTransaction: null,
      };
    case SPECULATE_TRANSACTION_SUCCESS:
      return {
        ...state,
        speculateTransactionInProgress: false,
        speculatedTransaction: payload.transaction,
      };
    case SPECULATE_TRANSACTION_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return {
        ...state,
        speculateTransactionInProgress: false,
        speculateTransactionError: payload,
      };

    case FETCH_TRANSACTION_SUCCESS:
      return {
        ...state,
        currentTransactionRef: payload.transaction
      }

    case VALIDATE_PROMO_REQUEST:
      return {
        ...state,
        validatePromoInProgress: true,
        validatePromoError: null,
      };
    case VALIDATE_PROMO_SUCCESS:
      const speculatedTransactionMaybe = !payload.transaction ? {} : { speculatedTransaction: payload.transaction };
      return {
        ...state,
        validatePromoInProgress: false,
        validatePromoInfo: payload.info,
        ...speculatedTransactionMaybe
      };
    case VALIDATE_PROMO_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return {
        ...state,
        validatePromoInProgress: false,
        validatePromoError: payload,
      };

    case INITIATE_ORDER_REQUEST:
      return { ...state, initiateOrderError: null };
    case INITIATE_ORDER_SUCCESS:
      return { ...state, transaction: payload };
    case INITIATE_ORDER_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return { ...state, initiateOrderError: payload };

    case CONFIRM_PAYMENT_REQUEST:
      return { ...state, confirmPaymentError: null };
    case CONFIRM_PAYMENT_SUCCESS:
      return state;
    case CONFIRM_PAYMENT_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return { ...state, confirmPaymentError: payload };

    case STRIPE_CUSTOMER_REQUEST:
      return { ...state, stripeCustomerFetched: false };
    case STRIPE_CUSTOMER_SUCCESS:
      return { ...state, stripeCustomerFetched: true };
    case STRIPE_CUSTOMER_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return { ...state, stripeCustomerFetchError: payload };

    default:
      return state;
  }
}

// ================ Selectors ================ //

// ================ Action creators ================ //

export const setInitialValues = initialValues => ({
  type: SET_INITAL_VALUES,
  payload: pick(initialValues, Object.keys(initialState)),
});

const initiateOrderRequest = () => ({ type: INITIATE_ORDER_REQUEST });

const initiateOrderSuccess = order => ({
  type: INITIATE_ORDER_SUCCESS,
  payload: order,
});

const initiateOrderError = e => ({
  type: INITIATE_ORDER_ERROR,
  error: true,
  payload: e,
});

const confirmPaymentRequest = () => ({ type: CONFIRM_PAYMENT_REQUEST });

const confirmPaymentSuccess = orderId => ({
  type: CONFIRM_PAYMENT_SUCCESS,
  payload: orderId,
});

const confirmPaymentError = e => ({
  type: CONFIRM_PAYMENT_ERROR,
  error: true,
  payload: e,
});

export const speculateTransactionRequest = () => ({ type: SPECULATE_TRANSACTION_REQUEST });

export const speculateTransactionSuccess = transaction => ({
  type: SPECULATE_TRANSACTION_SUCCESS,
  payload: { transaction },
});

export const speculateTransactionError = e => ({
  type: SPECULATE_TRANSACTION_ERROR,
  error: true,
  payload: e,
});

export const stripeCustomerRequest = () => ({ type: STRIPE_CUSTOMER_REQUEST });
export const stripeCustomerSuccess = () => ({ type: STRIPE_CUSTOMER_SUCCESS });
export const stripeCustomerError = e => ({
  type: STRIPE_CUSTOMER_ERROR,
  error: true,
  payload: e,
});

export const validatePromoRequest = () => ({ type: VALIDATE_PROMO_REQUEST });

export const validatePromoSuccess = ({ transaction, info }) => ({
  type: VALIDATE_PROMO_SUCCESS,
  payload: { transaction, info },
});

export const validatePromoError = e => ({
  type: VALIDATE_PROMO_ERROR,
  error: true,
  payload: e,
});

export const fetchTransactionSuccess = transaction => ({
  type: FETCH_TRANSACTION_SUCCESS,
  payload: { transaction },
});

/* ================ Thunks ================ */

export const initiateOrder = (orderParams, transactionId) => (dispatch, getState, sdk) => {
  dispatch(initiateOrderRequest());
  // If we already have a transaction ID, we should transition, not
  // initiate.

  // selectedPaymentMethod: p24, defaultCard, replaceCard, onetimeCardPayment
  const { selectedPaymentMethod } = orderParams;

  const isTransition = !!transactionId;

  // it can be only p24 or card, not both of them due to different process
  // process with card payment can not be continued with p24
  const paymentMethodTypes = [selectedPaymentMethod === 'p24' ? 'p24' : 'card'];

  const bodyParams = transactionId
    ? {
      id: transactionId,
      transition: selectedPaymentMethod === 'p24' ? TRANSITION_REQUEST_PUSH_PAYMENT_AFTER_ENQUIRY : TRANSITION_REQUEST_PAYMENT_AFTER_ENQUIRY,
      params: {
        ...orderParams,
        paymentMethodTypes
      }
    }
    : {
      processAlias: config.bookingProcessAlias,
      transition: selectedPaymentMethod === 'p24' ? TRANSITION_REQUEST_PUSH_PAYMENT : TRANSITION_REQUEST_PAYMENT,
      params: {
        ...orderParams,
        paymentMethodTypes
      }
    };

  const queryParams = {
    include: ['booking', 'provider'],
    expand: true,
  };

  const handleSucces = response => {
    // const entities = denormalisedResponseEntities(response);
    const order = response.data.data;
    dispatch(initiateOrderSuccess(order));
    dispatch(fetchCurrentUserHasOrdersSuccess(true));
    return order;
  };

  const handleError = e => {
    dispatch(initiateOrderError(storableError(e)));
    const transactionIdMaybe = transactionId ? { transactionId: transactionId.uuid } : {};
    log.error(e, 'initiate-order-failed', {
      ...transactionIdMaybe,
      listingId: orderParams.listingId.uuid,
      bookingStart: orderParams.bookingStart,
      bookingEnd: orderParams.bookingEnd,
    });
    throw e;
  };

  const { deliveryToHomeAddress, deliveryToProviderId, invoice, customerType } = orderParams.protectedData;
  const deliveryToHomeAddressMaybe = deliveryToHomeAddress ? { deliveryToHomeAddress: { ...deliveryToHomeAddress, address2: '', countryId: 'pl' } } : {};
  const deliveryToProviderIdMaybe = deliveryToProviderId ? { deliveryToProviderId } : {};

  const invoiceInMetaMaybe = invoice ? { invoice } : {};
  const customerTypeMaybe = customerType ? { customerType } : {};

  return sdk.newSdk.transactions
    .create({
      firstName: orderParams.protectedData.customerFirstName,
      lastName: orderParams.protectedData.customerLastName,
      receiverName: orderParams.protectedData.receiver,
      phone: orderParams.protectedData.customerPhoneNumber,
      start: orderParams.bookingStart,
      end: orderParams.bookingEnd,
      providerId: orderParams.providerId,
      voucherCode: orderParams.voucherCode,
      ...deliveryToHomeAddressMaybe,
      ...deliveryToProviderIdMaybe,
      meta: {
        ...customerTypeMaybe,
        ...invoiceInMetaMaybe
      },
      listings: orderParams.listings.map(l => (
        {
          id: l.listing.id.uuid,
          seats: l.attributes.amount
        }
      )
      )
    })
    .then(handleSucces)
    .catch(handleError);
};

export const fetchTransaction = (id) => async (dispatch, getState, sdk) => {
  return sdk.newSdk.transactions.show({ id, include: [
    'user',
    'user.image',
    'provider',
    'provider.image',
    'listings',
    'deliveryToProvider',
    'listings.images'
  ]})
    .then(res => {
      dispatch(addMarketplaceEntities(res))
      dispatch(fetchTransactionSuccess(res.data.data))
    })
  ;
}

export const confirmPayment = orderParams => async (dispatch, getState, sdk) => {
  dispatch(confirmPaymentRequest());

  const bodyParams = {
    id: orderParams.transactionId.uuid,
  };

  try {
    const response = await sdk.newSdk.transactions.confirmPayment({ ...bodyParams, include: ['listings', 'provider'] });

    sendPurchaseEvent(response);

    const order = response.data.data;
    dispatch(confirmPaymentSuccess(order.id));
    return order;
  } catch (e) {
    dispatch(confirmPaymentError(storableError(e)));
    const transactionIdMaybe = orderParams.transactionId
      ? { transactionId: orderParams.transactionId.uuid }
      : {};
    log.error(e, 'initiate-order-failed', {
      ...transactionIdMaybe,
    });
    throw e;
  }
};

export const confirmP24Payment = orderParams => async (dispatch, getState, sdk) => {
  dispatch(confirmPaymentRequest());

  const bodyParams = {
    id: orderParams.transactionId.uuid,
  };

  try {
    const response = await sdk.newSdk.transactions.confirmPayment({ ...bodyParams, include: ['listings', 'provider'] });

    sendPurchaseEvent(response);

    const order = response.data.data;
    dispatch(addMarketplaceEntities(response))
    dispatch(confirmPaymentSuccess(order.id));
    return response;
  } catch (e) {
    dispatch(confirmPaymentError(storableError(e)));
    const transactionIdMaybe = orderParams.transactionId
      ? { transactionId: orderParams.transactionId.uuid }
      : {};
    log.error(e, 'initiate-order-failed', {
      ...transactionIdMaybe,
    });
    throw e;
  }
};

export const sendMessage = params => (dispatch, getState, sdk) => {
  const message = params.message;
  const orderId = params.id;

  if (message) {
    return sdk.messages
      .send({ transactionId: orderId, content: message })
      .then(() => {
        return { orderId, messageSuccess: true };
      })
      .catch(e => {
        log.error(e, 'initial-message-send-failed', { txId: orderId });
        return { orderId, messageSuccess: false };
      });
  } else {
    return Promise.resolve({ orderId, messageSuccess: true });
  }
};

/**
 * Initiate the speculative transaction with the given booking details
 *
 * The API allows us to do speculative transaction initiation and
 * transitions. This way we can create a test transaction and get the
 * actual pricing information as if the transaction had been started,
 * without affecting the actual data.
 *
 * We store this speculative transaction in the page store and use the
 * pricing info for the booking breakdown to get a proper estimate for
 * the price with the chosen information.
 */
export const speculateTransaction = (orderParams, validatingPromoCode = false) => (dispatch, getState, sdk) => {
  !validatingPromoCode && dispatch(speculateTransactionRequest());
  validatingPromoCode && dispatch(validatePromoRequest());

  const params = {
    ...orderParams,
    cardToken: 'CheckoutPage_speculative_card_token',
  };

  const handleSuccess = response => {
    const entities = denormalisedResponseEntities(response);
    if (entities.length !== 1) {
      throw new Error('Expected a resource in the speculate response');
    }
    const tx = entities[0];
    if (validatingPromoCode) {
      if (!tx.attributes.lineItems.filter(li => !!li).find(li => li.code === 'promo-discount') && orderParams.voucherCode) {
        dispatch(validatePromoSuccess({ info: "CheckoutPage.invalidCode" }));
      } else {
        dispatch(validatePromoSuccess({ transaction: tx }))
      }
    }
    dispatch(speculateTransactionSuccess(tx));
    return response
  };

  const handleError = e => {
    const { listings, bookingStart, bookingEnd } = params;

    const cartListingId = listings.map(({ id }) => (id.uuid));

    console.error(e, 'speculate-transaction-failed', {
      cartListingId,
      bookingStart,
      bookingEnd,
    });
    validatingPromoCode && dispatch(validatePromoSuccess({ info: "CheckoutPage.invalidCode" }));
    return dispatch(speculateTransactionError(storableError(e)));
  };

  return sdk.newSdk.transactions
    .create({
      ...EMULATED_TRANSACTION,
      start: orderParams.bookingStart.toISOString().substring(0, 10),
      end: orderParams.bookingEnd.toISOString().substring(0, 10),
      providerId: orderParams.providerId,
      voucherCode: orderParams.voucherCode,
      listings: orderParams.listings.map(l => (
        {
          id: l.listing.id.uuid,
          seats: l.attributes.amount
        }
      )
      )
    })
    .then(handleSuccess)
    .catch(handleError);
};

export const speculateTransactionBeforeCart = (orderParams) => (dispatch, getState, sdk) => {
  return sdk.newSdk.cartPreorders.create({
    emulate: true,
    providerId: orderParams.providerId,
    start: orderParams.bookingStart.toISOString().substring(0, 10),
    end: orderParams.bookingEnd.toISOString().substring(0, 10),
    listings: [{
      listingId: orderParams.listingId,
      amount: 1
    }]
  })
    .then(res => res)
    .catch(e => dispatch(speculateTransactionError(storableError(e))));
}

// StripeCustomer is a relantionship to currentUser
// We need to fetch currentUser with correct params to include relationship
export const stripeCustomer = () => (dispatch, getState, sdk) => {
  dispatch(stripeCustomerRequest());

  return dispatch(fetchCurrentUser())
    .then(response => {
      dispatch(stripeCustomerSuccess());
    })
    .catch(e => {
      dispatch(stripeCustomerError(storableError(e)));
    });
};

export const validatePromo = (orderParams, transactionId) => async (dispatch, getState, sdk) => {
  dispatch(validatePromoRequest());

  const validationResponse = await validatePromoCode({ code: orderParams.promoCode });

  if (false) {
    // if (!validationResponse || !validationResponse.isValid) {
    dispatch(validatePromoSuccess({ info: "CheckoutPage.invalidCode" }))
  } else {
    const params = {
      ...orderParams,

      cardToken: 'CheckoutPage_speculative_card_token',
      voucherCode: orderParams.promoCode
    };

    const handleSuccess = response => {
      const entities = denormalisedResponseEntities(response);
      if (entities.length !== 1) {
        throw new Error('Expected a resource in the speculate response');
      }
      const tx = entities[0];
      return dispatch(validatePromoSuccess({ transaction: tx }));
    };

    const handleError = e => {
      const { listingId, bookingStart, bookingEnd } = params;
      log.error(e, 'speculate-transaction-failed', {
        listingId: listingId.uuid,
        bookingStart,
        bookingEnd,
      });
      return dispatch(validatePromoError(storableError(e)));
    };

    return sdk.newSdk.transactions
      .create({
        ...EMULATED_TRANSACTION,
        voucherCode: orderParams.promoCode,
        start: orderParams.bookingStart.toISOString().substring(0, 10),
        end: orderParams.bookingEnd.toISOString().substring(0, 10),
        providerId: orderParams.providerId,
        listings: [
          {
            id: orderParams.listingId.uuid,
            seats: 1
          }
        ]
      })
      .then(handleSuccess)
      .catch(handleError);
  }
}

export const sendPurchaseEvent = (response) => {
  //create a separate event for the service fee
  if (!response?.data?.data || !!response?.data?.data?.attributes.serviceFee) return;

  const {
    id: transactionId, attributes: { payinTotal, start, end }
  } = response.data.data;

  const listing = response.data.included.find(item => item.type === 'listing');
  const provider = response.data.included.find(item => item.type === 'provider');

  const event = {
    currency: payinTotal.currency,
    transactionId: transactionId.uuid,
    revenue: payinTotal.amount / 100,
    product: {
      name: listing.attributes.title,
      productId: listing.id.uuid,
      price: listing.attributes.price.amount / 100,
      category: listing.attributes.category,
      days: daysBetween(start, end),
      brand: provider.attributes.name
    }
  }

  purchaseEvent(event);
}

export const updateProfile = actionPayload => {
  return (dispatch, getState, sdk) => {
    return sdk.newSdk.currentUser
      .update({ ...actionPayload })
  };
};
