import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { parse } from '../../util/urlHelpers';
import config from '../../config';

// ================ Action types ================ //

export const FETCH_CONSOLE_USERS_REQUEST = 'app/ConsoleUserPage/FETCH_CONSOLE_USERS_REQUEST';
export const FETCH_CONSOLE_USERS_SUCCESS = 'app/ConsoleUserPage/FETCH_CONSOLE_USERS_SUCCESS';
export const FETCH_CONSOLE_USERS_ERROR = 'app/ConsoleUserPage/FETCH_CONSOLE_USERS_ERROR';

export const UPDATE_USER_REQUEST = 'app/ConsoleUserPage/UPDATE_USER_REQUEST';
export const UPDATE_USER_SUCCESS = 'app/ConsoleUserPage/UPDATE_USER_SUCCESS';
export const UPDATE_USER_ERROR = 'app/ConsoleUserPage/UPDATE_USER_ERROR';

// ================ Reducer ================ //

const initialState = {
  fetchInProgress: false,
  fetchUserListError: null,
  pagination: null,
  userList: [],
  updateUserInProgress: false,
  updateUserError: null,
};

export default function consoleUserReducer(state = initialState, action = {}) {
  const { type, payload } = action;

  switch (type) {
    case FETCH_CONSOLE_USERS_REQUEST:
      return { ...state, fetchInProgress: true, fetchUserListError: null };
    case FETCH_CONSOLE_USERS_SUCCESS: {
      return {
        ...state,
        fetchInProgress: false,
        userList: payload.data.data,
        pagination: payload.data.meta,
      };
    }
    case FETCH_CONSOLE_USERS_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, fetchInProgress: false, fetchUserListError: payload };
      case UPDATE_USER_REQUEST:
        return { ...state, updateUserInProgress: true, updateUserError: null };
      case UPDATE_USER_SUCCESS: {
        return {
          ...state,
          updateUserInProgress: false,
          updateUserError: payload.data.data,
        };
      }
      case UPDATE_USER_ERROR:
        console.error(payload); // eslint-disable-line
        return { ...state, updateUserInProgress: false, updateUserError: payload };
    default:
      return state;
  }
}

// ================ Action creators ================ //

const fetchUserListRequest = () => ({ type: FETCH_CONSOLE_USERS_REQUEST });

const fetchUserListSuccess = response => ({
  type: FETCH_CONSOLE_USERS_SUCCESS,
  payload: response,
});

const fetchUserListError = e => ({
  type: FETCH_CONSOLE_USERS_ERROR,
  error: true,
  payload: e,
});

const updateUserRequest = () => ({ type: UPDATE_USER_REQUEST });

const updateUserSuccess = response => ({
  type: UPDATE_USER_SUCCESS,
  payload: response,
});

const updateUserError = e => ({
  type: UPDATE_USER_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //

const CONSOLE_PAGE_SIZE = 100;

export const loadData = (params ,search) => (dispatch, getState, sdk) => {
  const { page = 1, phrase, countryId = config.custom.countryId, banned } = parse(search);
  const paginationParams = {
    page,
    per_page: CONSOLE_PAGE_SIZE,
  };

  const queryParams = {
    ...paginationParams,
    phrase,
    banned,
    language: countryId,
  };

  dispatch(fetchUserListRequest());

  return sdk.newSdk.users
    .query(queryParams)
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(fetchUserListSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(fetchUserListError(e));
      throw e;
    });
};

export const requestUpdateUser = data => {
  return (dispatch, getState, sdk) => {
    dispatch(updateUserRequest());

    return sdk.newSdk.users
      .update(data)
      .then(response => {
        dispatch(addMarketplaceEntities(response));
        dispatch(updateUserSuccess(response));
        return response;
      })
      .catch(e => {
        dispatch(updateUserError(e));
        throw e;
      });
  };
};
