import React, { useState, useEffect } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { arrayOf, bool, shape, string, func } from 'prop-types';

import { isScrollingDisabled } from '../../ducks/UI.duck';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { loadData, requestUpdateUser } from './ConsoleUserPage.duck';
import { PaginationLinks, ConsoleWrapper, IconSpinner, Input, Select } from '../../components';
import UserItem from './UserItem/UserItem';
import { parse, stringify } from '../../util/urlHelpers';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import config from '../../config';
import { countryId as defaultCountryId } from '../../marketplace-custom-config';

import css from './ConsoleUserPage.css';

const countries = [
  { countryId: 'all', country: 'Console.all' },
  ...config.countryNames
];

const bannedOptions = [
  { bannedValue: 'all', text: 'ConsoleUsersPage.allUser' },
  { bannedValue: true, text: 'ConsolePage.yes' },
  { bannedValue: false, text: 'ConsolePage.no' },
];

const ConsoleUserPageComponent = props => {
  const {

    users,
    fetchInProgress,
    scrollingDisabled,
    fetchUserListError,
    pagination,
    intl,
    location: { search, pathname },
    history,
    tab,
    name,
    updateUser,
    updateUserInProgress,
    currentUser
  } = props;
  const { phrase, page, countryId = config.custom.countryId, banned } = parse(search);
  const [searchPhrase, setSearchPhrase] = useState(phrase);
  const isAdmin = currentUser && currentUser.attributes.isAdmin;
  const adminCountries =  currentUser && currentUser.attributes.adminForCountries;
  const defaultAdminCountryId = (isAdmin || (adminCountries && adminCountries.includes(defaultCountryId)))
    ? defaultCountryId
    : adminCountries ? adminCountries[0] : null;

  const pagingLinks =
    !fetchInProgress && pagination && pagination?.totalPages > 1 ? (
      <PaginationLinks
        className={css.pagination}
        pageName={name}
        pagePathParams={{ tab }}
        pageSearchParams={parse(search)}
        pagination={pagination}
      />
    ) : null;

  const errorMessage = fetchUserListError ? (
    <p className={css.error}>{fetchUserListError?.message}</p>
  ) : null;

  const noResultsMessage =
    !fetchInProgress && !users?.length && !fetchUserListError ? (
      <p>
        <FormattedMessage id="Console.noUsersFound" />
      </p>
    ) : null;

  const handleCountryChange = event => {
    const searchCountryId = event.target.value === 'all' ? undefined : event.target.value;

    history.push({
      pathname,
      search: `${stringify({ ...parse(search), countryId: searchCountryId, page: 1 })}`,
    });
  };

  const handleChange = event => {
    setSearchPhrase(event.target.value);
  };

  const handleBannedChange = event => {
    const bannedParam = event.target.value === 'all' ? undefined : event.target.value;

    history.push({
      pathname,
      search: `${stringify({
        ...parse(search),
        banned: bannedParam,
        page: 1,
      })}`,
    });
  };

  const handleSubmit = event => {
    event.preventDefault();
    const searchCountryId = event.target.value === 'all' ? undefined : event.target.value;

    history.push({
      pathname,
      search: `${stringify({
        ...parse(search),
        phrase: searchPhrase || undefined,
        page: 1,
        countryId: searchCountryId,
      })}`,
    });
  };

  const userSearch = (
    <form onSubmit={handleSubmit} className={css.searchInputWrapper}>
      <Input
        value={searchPhrase}
        onChange={handleChange}
        placeholder={intl.formatMessage({ id: 'Console.searchUser' })}
        label={intl.formatMessage({ id: 'Console.search' })}
        isValidation={false}
      />
    </form>
  );

  useEffect(() => {
    if (!page && currentUser) {
      setSearchPhrase('');
      history.push({
        pathname,
        search: `${stringify({ ...parse(search), countryId: defaultAdminCountryId, page: 1 })}`,
      });
    }
  }, [page, currentUser]);

  return (
    <ConsoleWrapper scrollingDisabled={scrollingDisabled} name={name} tab={tab}>
      {errorMessage}
      <div className={css.filterWrapper}>
        <div className={css.filterContainer}>
          <Select
            value={countryId}
            onChange={handleCountryChange}
            label={intl.formatMessage({ id: 'ConsoleUserPage.country' })}
            isValidation={false}
          >
            <option hidden value="">
              {intl.formatMessage({ id: 'Console.select' })}
            </option>
            {countries.map(({ countryId, country }) => (
              <option value={countryId}>{intl.formatMessage({ id: country })}</option>
            ))}
          </Select>
          <Select
            value={typeof banned === 'undefined' ? 'all' : banned}
            onChange={handleBannedChange}
            label={intl.formatMessage({id: "ConsoleUserPage.banned" })}
            isValidation={false}
          >
            <option hidden value="">
              {intl.formatMessage({ id: 'Console.select' })}
            </option>
            {bannedOptions.map(({ bannedValue, text }) => (
              <option value={bannedValue}>{intl.formatMessage({ id: text })}</option>
            ))}
          </Select>
          {userSearch}
        </div>
        {!!users.length && <p className={css.totalItems}>
          {intl.formatMessage({ id: 'Console.numberOfItems' })}:{' '}
          <span>{pagination.totalItems}</span>
        </p>}
      </div>
      {fetchInProgress ? (
        <div className={css.listItemsLoading}>
          <IconSpinner />
        </div>
      ) : (
        <ul>
        {users?.map(user => {
            const {
              id: { uuid },
              attributes: { firstName, lastName, language, email, createdAt, banned },
            } = user;
            const date = intl.formatDate(createdAt, {
              year: 'numeric',
              month: 'long',
              day: 'numeric',
            });

            return (
              <UserItem
                key={uuid}
                userId={uuid}
                firstName={firstName}
                lastName={lastName}
                language={language}
                email={email}
                createdAt={date}
                updateUser={updateUser}
                updateUserInProgress={updateUserInProgress}
                banned={banned}
              />
            );
          })}
        </ul>
      )}
      {noResultsMessage}
      {pagingLinks}
    </ConsoleWrapper>
  );
};

ConsoleUserPageComponent.defaultProps = {
  users: [],
  fetchInProgress: false,
  fetchUserListError: null,
  pagination: null,
  scrollingDisabled: false,
  name: null,
  tab: null,
};

ConsoleUserPageComponent.propTypes = {
  fetchInProgress: bool.isRequired,
  fetchUserListError: propTypes.error,
  pagination: propTypes.pagination,
  scrollingDisabled: bool.isRequired,
  users: arrayOf(propTypes.userDetails),
  intl: intlShape.isRequired,
  name: string.isRequired,
  tab: string.isRequired,
  history: shape({ push: func.isRequired }).isRequired,
  location: shape({ search: string, pathname: string }).isRequired,
  updateUser: func.isRequired,
  updateUserInProgress: bool.isRequired,
};

const mapStateToProps = state => {
  const {
    fetchInProgress,
    fetchUserListError,
    pagination,
    userList,
    updateUserInProgress,
  } = state.ConsoleUserPage;
  const { currentUser } = state.user;

  return {
    fetchInProgress,
    fetchUserListError,
    pagination,
    scrollingDisabled: isScrollingDisabled(state),
    users: getMarketplaceEntities(state, userList),
    updateUserInProgress,
    currentUser
  };
};

const mapDispatchToProps = dispatch => ({
  updateUser: data => dispatch(requestUpdateUser(data)),
});

const ConsoleUserPage = compose(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl,
  withRouter
)(ConsoleUserPageComponent);

ConsoleUserPage.loadData = loadData;

export default ConsoleUserPage;
