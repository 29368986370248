import React, { useEffect } from 'react';
import {
  AboutPage,
  AuthenticationPage,
  AdminPage,
  BusinessResultsPage,
  BusinessAnalysisPage,
  BusinessSettingsPage,
  BusinessDktPage,
  BookingConditionsPage,
  CheckoutPage,
  P24StatusPage,
  ContactDetailsPage,
  EditListingPage,
  EmailVerificationPage,
  ExchangeCodePage,
  FAQPage,
  InboxPage,
  InvoiceSettingsPage,
  LandingPage,
  ListingPage,
  ManageListingsPage,
  NotFoundPage,
  PasswordChangePage,
  PasswordRecoveryPage,
  PasswordResetPage,
  StripePayoutPage,
  StripePayoutSettingsPage,
  PaymentMethodsPage,
  PrivacyPolicyPage,
  ProfilePage,
  ProfileSettingsPage,
  SeoPage,
  Seo2Page,
  Seo3Page,
  Seo4Page,
  Seo5Page,
  Seo6Page,
  Seo7Page,
  Seo8Page,
  SearchPage,
  ProductSeoPage,
  StyleguidePage,
  TermsOfServicePage,
  TransactionPage,
  ConsoleTransactionPage,
  ConsoleUserPage,
  ConsoleProviderPage,
  CartPage,
  LegalInformationPage,
  ConsoleReviewPage,
  TeamPage,
  ConsoleCouponsPage,
  PaymentPage,
  PayoutPage,
  PayoutTransactionsPage,
  PayoutFuturePage,
  EditProductPage,
  EditItemPage,
  ConsoleProductCatalogPage,
  ProviderPage,
  BusinessProductsPage,
  ServiceFeeInboxPage,
  ServiceFeePage, CheckoutPageServiceFee,
} from './containers';

// routeConfiguration needs to initialize containers first
// Otherwise, components will import form container eventually and
// at that point css bundling / imports will happen in wrong order.
import { NamedRedirect, TransactionSuccess } from './components';

export const ACCOUNT_SETTINGS_PAGES = [
  'ContactDetailsPage',
  'PasswordChangePage',
  'PaymentMethodsPage',
];

export const BUSINESS_PAGES = [
  'BusinessSettingsPage',
  'StripePayoutPage',
  'BusinessResultsPage',
  'ManageListingsPage',
  'BusinessDktPage',
  'BusinessAnalysis',
  'BookingConditionsPage',
  'AdminPage'
];

// https://en.wikipedia.org/wiki/Universally_unique_identifier#Nil_UUID
const draftId = '00000000-0000-0000-0000-000000000000';
const draftSlug = 'draft';

const RedirectToLandingPage = () => <NamedRedirect name="LandingPage" />;

const seoPagesMaybe = process.env.REACT_APP_COUNTRY_ID === 'pl' ? [
  {
    path: '/wypozyczalnia-nart',
    name: 'SkiRentPage',
    component: () => <SeoPage />
  },
  {
    path: '/wypozyczalnia-sprzetu-sportowego',
    name: 'EquipmentRentPage',
    component: () => <Seo3Page />
  },
  {
    path: '/wypozyczalnia-rowerow',
    name: 'BikeRentPage',
    component: () => <Seo2Page />
  },
  {
    path: '/wypozyczalnia-sprzetu-turystycznego',
    name: 'TourismRentPage',
    component: () => <Seo4Page />
  },
  {
    path: '/wypozyczalnia-kajakow',
    name: 'KayakRentPage',
    component: () => <Seo5Page />
  },
  {
    path: '/wypozyczalnia-desek-sup',
    name: 'SupRentPage',
    component: () => <Seo6Page />
  },
  {
    path: '/wypozyczalnia-trenazerow-rowerowych',
    name: 'TrademillPage',
    component: () => <Seo7Page />
  },
  {
    path: '/wypozyczalnia-sprzetu-fitness',
    name: 'FitnessPage',
    component: () => <Seo8Page />
  },
] : [];

// NOTE: Most server-side endpoints are prefixed with /api. Requests to those
// endpoints are indended to be handled in the server instead of the browser and
// they will not render the application. So remember to avoid routes starting
// with /api and if you encounter clashing routes see server/index.js if there's
// a conflicting route defined there.

// Our routes are exact by default.
// See behaviour from Routes.js where Route is created.
const routeConfiguration = () => {
  return [
    {
      path: `/p/:name`,
      name: 'ProductSeoPage',
      component: (props) => <ProductSeoPage {...props} />
    },
    {
      path: '/',
      name: 'LandingPage',
      component: props => <LandingPage {...props} />,
    },
    ...seoPagesMaybe,
    {
      path: '/about',
      name: 'AboutPage',
      component: AboutPage,
    },
    {
      path: '/faq',
      name: 'FAQPage',
      component: FAQPage,
    },
    {
      path: '/s',
      name: 'SearchPage',
      component: props => <SearchPage {...props} />,
      loadData: SearchPage.loadData,
    },
    {
      path: '/logged-in',
      name: 'ExchangeCodePage',
      component: props => <ExchangeCodePage {...props} />
    },
    {
      path: '/s/filters',
      name: 'SearchFiltersPage',
      component: props => <SearchPage {...props} tab="filters" />,
      loadData: SearchPage.loadData,
    },
    {
      path: '/s/listings',
      name: 'SearchListingsPage',
      component: props => <SearchPage {...props} tab="listings" />,
      loadData: SearchPage.loadData,
    },
    {
      path: '/s/map',
      name: 'SearchMapPage',
      component: props => <SearchPage {...props} tab="map" />,
      loadData: SearchPage.loadData,
    },
    {
      path: '/l',
      name: 'ListingBasePage',
      component: RedirectToLandingPage,
    },
    {
      path: '/l/:slug/:id',
      name: 'ListingPage',
      component: props => <ListingPage {...props} />,
      loadData: ListingPage.loadData,
    },
    // {
    //   path: '/l/:slug/:id/checkout',
    //   name: 'CheckoutPage',
    //   auth: true,
    //   component: props => <CheckoutPage {...props} />,
    //   setInitialValues: CheckoutPage.setInitialValues,
    // },
    {
      path: '/checkout/:cartId',
      name: 'CheckoutPage',
      auth: true,
      component: props => <CheckoutPage {...props} />,
      setInitialValues: CheckoutPage.setInitialValues,
    },
    {
      path: '/checkout/fee/:feeId',
      name: 'CheckoutPageServiceFee',
      auth: true,
      component: props => <CheckoutPageServiceFee {...props} />,
      setInitialValues: CheckoutPageServiceFee.setInitialValues,
    },
    {
      path: '/payment/:transactionId',
      name: 'PaymentPage',
      auth: true,
      component: props => <PaymentPage {...props} />,
      setInitialValues: PaymentPage.setInitialValues,
    },
    {
      path: '/l/:slug/:id/:variant',
      name: 'ListingPageVariant',
      auth: true,
      authPage: 'LoginPage',
      component: props => <ListingPage {...props} />,
      loadData: ListingPage.loadData,
    },
    {
      path: '/l/new',
      name: 'NewListingPage',
      auth: true,
      component: () => (
        <NamedRedirect
          name="EditListingPage"
          params={{ slug: draftSlug, id: draftId, type: 'new', tab: 'description' }}
        />
      ),
    },
    {
      path: '/l/:slug/:id/:type/:tab',
      name: 'EditListingPage',
      auth: true,
      component: props => <EditListingPage {...props} />,
      loadData: EditListingPage.loadData,
    },
    {
      path: '/l/:slug/:id/:type/:tab/:returnURLType',
      name: 'EditListingStripeOnboardingPage',
      auth: true,
      component: props => <EditListingPage {...props} />,
      loadData: EditListingPage.loadData,
    },
    {
      path: '/product/:id/:type',
      name: 'EditProductPage',
      auth: true,
      component: props => <EditProductPage {...props} />,
      loadData: EditProductPage.loadData,
    },
    {
      path: '/item/:id/:type',
      name: 'EditItemPage',
      auth: true,
      component: props => <EditItemPage {...props} />,
      loadData: EditItemPage.loadData,
    },

    // Canonical path should be after the `/l/new` path since they
    // conflict and `new` is not a valid listing UUID.
    {
      path: '/l/:id',
      name: 'ListingPageCanonical',
      component: props => <ListingPage {...props} />,
      loadData: ListingPage.loadData,
    },
    {
      path: '/u',
      name: 'ProfileBasePage',
      component: RedirectToLandingPage,
    },
    {
      path: '/u/:id',
      name: 'ProfilePage',
      component: props => <ProfilePage {...props} />,
      loadData: ProfilePage.loadData,
    },
    {
      path: '/profile-settings',
      name: 'ProfileSettingsPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <ProfileSettingsPage {...props} />,
      loadData:  ProfileSettingsPage.loadData
    },
    {
      path: '/invoice-settings',
      name: 'InvoiceSettingsPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <InvoiceSettingsPage {...props} />,
      loadData:  InvoiceSettingsPage.loadData
    },
    {
      path: '/login',
      name: 'LoginPage',
      component: props => <AuthenticationPage {...props} tab="login" />,
    },
    {
      path: '/signup',
      name: 'SignupPage',
      component: props => <AuthenticationPage {...props} tab="signup" />,
    },
    {
      path: '/recover-password',
      name: 'PasswordRecoveryPage',
      component: props => <PasswordRecoveryPage {...props} />,
    },
    {
      path: '/inbox',
      name: 'InboxBasePage',
      auth: true,
      authPage: 'LoginPage',
      component: () => <NamedRedirect name="InboxPage" params={{ tab: 'orders', providerId: 'orders' }} />,
    },
    {
      path: '/inbox/:tab',
      name: 'InboxBasicPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <InboxPage {...props} />,
      loadData: InboxPage.loadData,
    },
    {
      path: '/inbox/:tab/:providerId',
      name: 'InboxPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <InboxPage {...props} name="InboxPage"/>,
      loadData: InboxPage.loadData,
    },
    {
      path: '/order/:id',
      name: 'OrderPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <NamedRedirect name="OrderDetailsPage" params={{ ...props.params }} />,
    },
    {
      path: '/order/:id/push-payment-status',
      name: 'P24StatusPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <P24StatusPage {...props} transactionRole="customer" />,
      // loadData: params => TransactionPage.loadData({ ...params, transactionRole: 'customer' }),
      // setInitialValues: TransactionPage.setInitialValues,
    },
    {
      path: '/order/:id/details',
      name: 'OrderDetailsPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <TransactionPage {...props} transactionRole="customer" pageName='OrderDetailsPage' />,
      loadData: params => TransactionPage.loadData({ ...params, transactionRole: 'customer' }),
      setInitialValues: TransactionPage.setInitialValues,
    },
    {
      path: '/order/:id/details-success',
      name: 'OrderDetailsSuccessPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <TransactionPage
        {...props}
        transactionRole='customer'
        pageName='OrderDetailsSuccessPage'
        transactionSuccessComponent={<TransactionSuccess />}
      />,
      loadData: params => TransactionPage.loadData({ ...params, transactionRole: 'customer' }),
      setInitialValues: TransactionPage.setInitialValues,
    },
    {
      path: '/sale/:id',
      name: 'SalePage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <NamedRedirect name="SaleDetailsPage" params={{ ...props.params }} />,
    },
    {
      path: '/sale/:id/details',
      name: 'SaleDetailsPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <TransactionPage {...props} transactionRole="provider" pageName='OrderDetailsPage'/>,
      loadData: params => TransactionPage.loadData({ ...params, transactionRole: 'provider'}),
    },
    {
      path: '/listings/:providerId',
      name: 'ManageListingsPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <ManageListingsPage {...props} />,
      loadData: ManageListingsPage.loadData,
    },
    {
      path: '/payouts/:id',
      name: 'PayoutPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <PayoutPage {...props} name='PayoutPage'/>,
      loadData: PayoutPage.loadData,
    },
    {
      path: '/payouts/:id/future',
      name: 'PayoutFuturePage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <PayoutFuturePage {...props} name='PayoutFuturePage' />,
      loadData: PayoutFuturePage.loadData,
    },
    {
      path: '/payouts/:id/:payoutId',
      name: 'PayoutTransactionsPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <PayoutTransactionsPage {...props} name='PayoutTransactionsPage' />,
      loadData: PayoutTransactionsPage.loadData,
    },
    {
      path: '/business',
      name: 'BusinessPage',
      auth: true,
      authPage: 'LoginPage',
      component: () => <NamedRedirect name="BusinessResultsPage" />,
    },
    {
      path: '/business/results',
      name: 'BusinessResultsPage',
      auth: true,
      authPage: 'LoginPage',
      component: (props) => <BusinessResultsPage {...props} />,
    },
    {
      path: '/business/analysis',
      name: 'BusinessAnalysisPage',
      auth: true,
      authPage: 'LoginPage',
      component: (props) => <BusinessAnalysisPage {...props} analysis />,
    },
    {
      path: '/business/decathlon',
      name: 'BusinessDktPage',
      auth: true,
      authPage: 'LoginPage',
      component: (props) => <BusinessAnalysisPage {...props} />,
    },
    {
      path: '/business/products',
      name: 'BusinessProductsPage',
      auth: true,
      authPage: 'LoginPage',
      component: (props) => <BusinessProductsPage {...props} />,
    },
    {
      path: '/admin-rent',
      name: 'AdminPage',
      auth: true,
      authPage: 'LoginPage',
      component: (props) => <AdminPage {...props} />,
    },
    {
      path: '/business/conditions',
      name: 'BookingConditionsPage',
      auth: true,
      authPage: 'LoginPage',
      component: (props) => <BookingConditionsPage {...props} />,
    },
    {
      path: '/business/settings',
      name: 'BusinessSettingsPage',
      auth: true,
      authPage: 'LoginPage',
      component: (props) => <BusinessSettingsPage {...props} />,
      loadData: BusinessSettingsPage.loadData
    },
    {
      path: '/business/payout',
      name: 'StripePayoutSettingsPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <StripePayoutSettingsPage {...props} />,
      loadData: StripePayoutSettingsPage.loadData,
    },
    {
      path: '/business/payout/:providerId',
      name: 'StripePayoutPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <StripePayoutPage {...props} />,
      loadData: StripePayoutPage.loadData,
    },
    {
      path: '/business/payout/:providerId/:returnURLType',
      name: 'StripePayoutOnboardingPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <StripePayoutPage {...props} />,
      loadData: StripePayoutPage.loadData,
    },
    {
      path: '/account',
      name: 'AccountSettingsPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <PaymentMethodsPage {...props} />,
      loadData: PaymentMethodsPage.loadData,
    },
    // {
    //   path: '/account/contact-details',
    //   name: 'ContactDetailsPage',
    //   auth: true,
    //   authPage: 'LoginPage',
    //   component: props => <ContactDetailsPage {...props} />,
    //   loadData: ContactDetailsPage.loadData,
    // },
    // {
    //   path: '/account/change-password',
    //   name: 'PasswordChangePage',
    //   auth: true,
    //   authPage: 'LoginPage',
    //   component: props => <PasswordChangePage {...props} />,
    // },
    // {
    //   path: '/account/payment-methods',
    //   name: 'PaymentMethodsPage',
    //   auth: true,
    //   authPage: 'LoginPage',
    //   component: props => <PaymentMethodsPage {...props} />,
    //   loadData: PaymentMethodsPage.loadData,
    // },
    {
      path: '/console',
      name: 'ConsoleBasePage',
      auth: true,
      authPage: 'LoginPage',
      component:() => <NamedRedirect name="ConsoleTransactionPage" params={{ tab: "transactions" }} />,
    },
    {
      path: '/console/users',
      name: 'ConsoleUserPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <ConsoleUserPage {...props} name="ConsoleUserPage" tab="users" />,
      loadData: ConsoleUserPage.loadData,
    },
    {
      path: '/console/transactions',
      name: 'ConsoleTransactionPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <ConsoleTransactionPage {...props} name="ConsoleTransactionPage" tab="transactions" />,
      loadData: ConsoleTransactionPage.loadData,
    },
    {
      path: '/console/providers',
      name: 'ConsoleProviderPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <ConsoleProviderPage {...props} name="ConsoleProviderPage" tab="providers" />,
      loadData: ConsoleProviderPage.loadData,
    },
    {
      path: '/console/reviews',
      name: 'ConsoleReviewPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <ConsoleReviewPage {...props} name="ConsoleReviewPage" tab="reviews" />,
      loadData: ConsoleReviewPage.loadData,
    },
    {
      path: '/console/coupons',
      name: 'ConsoleCouponsPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <ConsoleCouponsPage {...props} name="ConsoleCouponsPage" tab="coupons" />,
      loadData: ConsoleCouponsPage.loadData,
    },
    {
      path: '/console/productCatalog',
      name: 'ConsoleProductCatalogPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <ConsoleProductCatalogPage {...props} name="ConsoleProductCatalogPage" tab="productCatalog" />,
      loadData: ConsoleProductCatalogPage.loadData,
    },
    {
      path: '/terms-of-service',
      name: 'TermsOfServicePage',
      component: props => <TermsOfServicePage {...props} />,
    },
    {
      path: '/privacy-policy',
      name: 'PrivacyPolicyPage',
      component: props => <PrivacyPolicyPage {...props} />,
    },
    {
      path: '/legal-information',
      name: 'LegalInformationPage',
      component: props => <LegalInformationPage {...props} />,
    },
    {
      path: '/styleguide',
      name: 'Styleguide',
      component: props => <StyleguidePage {...props} />,
    },
    {
      path: '/styleguide/g/:group',
      name: 'StyleguideGroup',
      component: props => <StyleguidePage {...props} />,
    },
    {
      path: '/styleguide/c/:component',
      name: 'StyleguideComponent',
      component: props => <StyleguidePage {...props} />,
    },
    {
      path: '/styleguide/c/:component/:example',
      name: 'StyleguideComponentExample',
      component: props => <StyleguidePage {...props} />,
    },
    {
      path: '/styleguide/c/:component/:example/raw',
      name: 'StyleguideComponentExampleRaw',
      component: props => <StyleguidePage raw {...props} />,
    },
    {
      path: '/notfound',
      name: 'NotFoundPage',
      component: props => <NotFoundPage {...props} />,
    },
    {
      path: '/zwrot',
      name: 'ReturnProductPage',
      component: () => <RedirectOutside />,
    },

    // Do not change this path!
    //
    // The API expects that the application implements /reset-password endpoint
    {
      path: '/reset-password',
      name: 'PasswordResetPage',
      component: props => <PasswordResetPage {...props} />,
    },

    // Do not change this path!
    //
    // The API expects that the application implements /verify-email endpoint
    {
      path: '/verify-email',
      name: 'EmailVerificationPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <EmailVerificationPage {...props} />,
      loadData: EmailVerificationPage.loadData,
    },
    {
      path: '/cart',
      name: 'CartPage',
      auth: false,
      authPage: 'LoginPage',
      component: props => <CartPage {...props} />,
      loadData: CartPage.loadData,
    },
    {
      path: '/team/:providerId',
      name: 'TeamPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <TeamPage {...props} />,
      loadData: TeamPage.loadData,
    },
    {
      path: '/provider/:providerId',
      name: 'ProviderPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <ProviderPage {...props} />,
    },
    {
      path: '/fee/:tab/:providerId',
      name: 'ServiceFeeInboxPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <ServiceFeeInboxPage {...props} name="ServiceFeeInboxPage"/>,
      loadData: ServiceFeeInboxPage.loadData,
    },
    {
      path: '/order/:id/fee',
      name: 'OrderFeePage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <NamedRedirect name="ServiceFeeDetailsPage" params={{ ...props.params }} />,
    },
    {
      path: '/order/:id/fee/details',
      name: 'ServiceFeeDetailsPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <ServiceFeePage {...props} transactionRole="customer" pageName='ServiceFeeDetailsPage' />,
      loadData: params => ServiceFeePage.loadData({ ...params, transactionRole: 'customer' }),
      setInitialValues: ServiceFeePage.setInitialValues,
    },
    {
      path: '/order/:id/fee/details-success',
      name: 'ServiceFeeDetailsSuccessPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <ServiceFeePage
        {...props}
        transactionRole='customer'
        pageName='ServiceFeeDetailsSuccessPage'
        transactionSuccessComponent={<TransactionSuccess />}
      />,
      loadData: params => ServiceFeePage.loadData({ ...params, transactionRole: 'customer' }),
      setInitialValues: ServiceFeePage.setInitialValues,
    },
    {
      path: '/sale/:id/fee',
      name: 'SaleFeePage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <NamedRedirect name="SaleServiceFeeDetailsPage" params={{ ...props.params }} />,
    },
    {
      path: '/sale/:id/fee/details',
      name: 'SaleServiceFeeDetailsPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <ServiceFeePage {...props} transactionRole='provider' pageName='SaleServiceFeeDetailsPage'/>,
      loadData: params => ServiceFeePage.loadData({ ...params, transactionRole: 'provider'}),
    },
  ];
};

const RedirectOutside = () => {
  useEffect(() => {
    window.location.href = "https://forms.gle/2D53Ght93dBBq4FW8";
  }, []);
  return (
    <></>
  )
}

export default routeConfiguration;
